<template>
  <v-row class="mt-4">
    <v-col>
      <v-card
        :loading="loadingGet"
      >
        <v-toolbar
          color="info"
          flat
          dense
        >
          <v-toolbar-title class="white--text font-weight-black">
            # {{ activityBranch.id || '--' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <validation-observer ref="observer">
          <v-form class="mt-4">
            <v-container class="">
              <v-row>
                <v-col
                  cols="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="description"
                  >
                    <v-text-field
                      v-model="newActivityBranch.description"
                      :disabled="loadingGet"
                      hide-details="auto"
                      label="Descrição"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
        <v-divider class="mt-4" />
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              class="text-left"
            >
              <btn-save
                :loading="loadingSave"
                @click="save"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash'

  export default {

    props: {
      formValidation: {
        type: Object,
        default: () => { return {} },
      },
      loadingSave: {
        type: Boolean,
        default: false,
      },
      loadingGet: {
        type: Boolean,
        default: false,
      },
      activityBranch: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        newActivityBranch: {
          description: null,
        },
      }
    },

    computed: {
      edit () {
        return !_.isEmpty(this.activityBranch)
      },
    },

    watch: {
      formValidation (val) {
        if (_.isEmpty(val)) return false

        return this.$refs.observer.setErrors(val)
      },

      activityBranch (val) {
        if (_.isEmpty(val)) return

        this.newActivityBranch = val
      },
    },

    methods: {
      save () {
        this.$refs.observer.reset()
        this.$emit('save', this.newActivityBranch)
      },
    },

  }
</script>
